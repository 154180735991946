import React, { useState } from 'react'
import 'twin.macro'
import { useForm } from 'react-hook-form'

import { ErrorMessage, ErrorMessageText, Form, FormButton, FormField, FormSection, TextInput } from '../../components/elements'
import { ROUTES } from '../../components/layout/Navigation'
import { OnboardingStep, useMatchOnboardingState } from '../../content/onboarding/OnboardingStep'
import { useNavigate } from '@reach/router'
import { Spinner } from '../../components/elements/Loading'
import { useEmitSubmitContactInformation } from '../../components/analytics/Events'
import { useAnalytics } from '../../components/analytics/AnalyticsWrapper'
import { useLocalStorage } from '../../components/environment/useLocalStorage'


type FormResults = {
  name: string,
  emailAddress: string,
  phoneNumber: string,
}

const OnboardingStep1 = () => (
  <OnboardingStep
    step={1}
    desktopTitle={'1. Tell us about yourself'}
    imageUrl='https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80'
  >
    <Step1Form />
  </OnboardingStep>
)

const Step1Form = () => {
 const navigate = useNavigate()
  const { actions, state } = useMatchOnboardingState()
  const { assignUserId, assignEmailAddress, split } = useAnalytics()
  const registerConversion = useEmitSubmitContactInformation()
  const [gclid] = useLocalStorage('gclid', '')
  const [fbclid] = useLocalStorage('fbclid', '')
  const [campaign] = useLocalStorage('campaign', '')

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const { register, handleSubmit, errors } = useForm<FormResults>({
    defaultValues: state
  })

  const onSubmit = async (data: FormResults) => {

    const { name, emailAddress, phoneNumber } = data

    if (!name || !emailAddress || !phoneNumber) {
      return
    }

    actions.update(data)

    registerConversion()

    const payload = {
      name,
      emailAddress,
      phoneNumber,
      gclid,
      fbclid,
      campaign,
      split,
      caseType: state.caseType

    }

    setError(false)
    setLoading(true)

    const response = await fetch('/.netlify/functions/match-onboarding-preregistration', {
      method: 'POST',
      body: JSON.stringify(payload)
    })

    setLoading(false)

    if (!response.ok) {
      setError(true)
    } else {
      const { secret, submissionId, record } = await response.json()

      assignUserId(submissionId)
      assignEmailAddress(emailAddress)

      actions.update({ secret, submissionId, record })

      navigate(ROUTES.onboarding.step2)
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} tw="sm:p-12">
      <FormField
        id={'name'}
        label={'Your Name'}
        error={errors.name}
        control={(
          <TextInput
            ref={register({ required: "This field is required" })}
            name="name"
            id="name"
            type="text"
          />
        )}
      />

      <FormField
        id={'emailAddress'}
        label={'Your Email'}
        error={errors.emailAddress}
        control={(
          <TextInput
            ref={register({
              required: "This field is required",
              pattern: {
                value: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                message: "Please enter a valid email address"
              }
            })}
            id="emailAddress"
            name="emailAddress"
            type="email"
          />
        )}
      />

      <FormField
        id={'phoneNumber'}
        label={'Your Phone Number'}
        error={errors.phoneNumber}
        control={(
          <TextInput
            ref={register({
              required: "This field is required",
              pattern: {
                value: /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/,
                message: "Please enter a valid UK phone number (+44 or 0 prefix)"
              }
            })}
            id="phoneNumber"
            name="phoneNumber"
            type="tel"
          />
        )}
      />

      <p tw="text-xs sm:text-sm text-gray-600">
        <strong>We will never send you spam.</strong>
        </p>
      <p tw="text-xs sm:text-sm text-gray-600">
        We ask for this information so we can discuss your case.
      </p>

      <FormSection tw="mt-4">
        <FormButton disabled={loading}>
          {loading ? <Spinner tw="h-6" /> : <>Continue to build case</>}
        </FormButton>
      </FormSection>


      {error && (
        <ErrorMessage tw="mb-4" title={'Something went wrong'}>
          <ErrorMessageText>
            Please try again or contact <a href="mailto:support@lawletter.co">support@lawletter.co</a>
          </ErrorMessageText>
        </ErrorMessage>
      )}


      <p tw="text-gray-700 font-medium text-xs sm:text-sm">
        By continuing you agree to
        our <a href={ROUTES.legal.privacyPolicy} target={'_blank'} tw="text-gray-500 underline">
          Privacy Policy
        </a> and <a href={ROUTES.legal.termsOfService} target={'_blank'} tw="text-gray-500 underline">
          Terms of Service
        </a>
      </p>

    </Form>
  )
}

export default OnboardingStep1
